// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-technologies-under-content-js": () => import("/opt/build/repo/src/templates/technologiesUnderContent.js" /* webpackChunkName: "component---src-templates-technologies-under-content-js" */),
  "component---src-templates-portfolio-under-content-js": () => import("/opt/build/repo/src/templates/portfolioUnderContent.js" /* webpackChunkName: "component---src-templates-portfolio-under-content-js" */),
  "component---src-templates-home-page-js": () => import("/opt/build/repo/src/templates/homePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-portfolio-js": () => import("/opt/build/repo/src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "component---src-templates-technologies-js": () => import("/opt/build/repo/src/templates/technologies.js" /* webpackChunkName: "component---src-templates-technologies-js" */),
  "component---src-templates-blog-post-list-js": () => import("/opt/build/repo/src/templates/blogPostList.js" /* webpackChunkName: "component---src-templates-blog-post-list-js" */),
  "component---src-templates-blog-js": () => import("/opt/build/repo/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

